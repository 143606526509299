import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Endoscopic Retrograde Cholangiopancreatography (ERCP)";

// Download link - http://www.fbrennangastro.com.au/downloads/gesaercp.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "endoscopy", "fluoroscopy", "pancreatic duct", "digestive system"]}
			type="article"
			description="Using ERCP (Endoscopic Retrograde Cholangiopancreatography) - a combination of endoscopy and fluoroscopy to diagnose issues in the digestive system"
		/>
		<H1>{title}</H1>
		<Par>
			Endoscopic retrograde cholangiopancreatography (ERCP) is a technique that combines the use of endoscopy and
			fluoroscopy to diagnose and treat certain problems of the digestive system such as gallstones, inflammatory
			strictures (scars) or cancer.
		</Par>
		<Par>
			The patient is sedated or anaesthetised. During the procedure a thin telescope (endoscope) is inserted down the
			throat to allow the doctor to see inside the digestive system.
		</Par>
		<Par>
			The doctor then injects a dye into the bile duct or pancreatic duct so it can be seen on an x-ray (fluroscopy)
			while the necessary procedure is performed.
		</Par>
	</Blog>
);
